import React from "react"
import Layout from "../components/Layout/Layout"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Card from "../components/Card"
import SEO from "../components/SEO/SEO"

import "./about.scss"
import ContactSection from "../components/ContactSection"

export const query = graphql`{
  flowerImage: file(relativePath: {eq: "IMG_3375.JPG"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  houseImage: file(relativePath: {eq: "IMG_1024.JPG"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  margoImage: file(relativePath: {eq: "margo.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  bayImage: file(relativePath: {eq: "IMG_4936.JPG"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`

const about = ({ data }) => {
  return (
    <Layout>
      <SEO title="About" />
      <div className="page about">
        <div className="section about__do">
          <div className="about__do-left">
            <Card className="about__card">
              <GatsbyImage
                image={data.flowerImage.childImageSharp.gatsbyImageData}
                className="about__card-image" />
            </Card>
          </div>
          <div className="about__do-right">
            <h2 className="section-title">About Us</h2>
            <p>
              Bay Meadow Farms is a family-run fresh cut specialty flower farm
              in Suttons Bay, Michigan. We grow our unique flowers on the same
              land our family has gardened on for over 100 years. We practice
              regenerative farming and have a passion for sharing our love of
              flowers with the community in Northern Michigan.
            </p>
            <p className="about__do-second-paragraph">
              Our flowers are available at the Glen Arbor farmers market and our
              farm stand in Suttons Bay. We can provide weddings flower buckets,
              specialty orders, and DIY events upon request! Make sure to
              sign-up for the newsletter to know when these are available for
              purchase on the website!
            </p>
            <p className="about__do-quote">
              Gardening is the memory of a hundred generations of women bending
              to the earth.
            </p>
          </div>
        </div>

        <div className="section about__who">
          <div className="about__who-left">
            <h2 className="section-title">History of the farm</h2>
            <p>
              We are the 5th generation of farmers on the land. The farm's
              original owners, Auntie Elizabeth (who Bay is named after) and her
              husband Daniel, moved on to the property in the early 1900s. They
              grew apples, cherries, or berries, and Auntie always kept a
              beautiful garden. Throughout the years, each generation has used
              the land in different ways. Our family has always been growers at
              heart.
            </p>
            <p className="about__who-second-paragraph">
              Bay Meadow Farms gets its name from Bay, who is nicknamed after
              Auntie, and Meadow from the sunny spot Margo grows the flowers.
            </p>
            <p className="about__who-second-paragraph">
              We love Leelanau County and what it stands for. Intentional
              living, connecting with family, hard work, and handmade, locally
              grown products.
            </p>
          </div>
          <div className="about__who-right">
            <Card className="about__card">
              <GatsbyImage
                image={data.houseImage.childImageSharp.gatsbyImageData}
                className="about__card-image" />
            </Card>
          </div>
        </div>
        <div className="section about__founders">
          <h2 className="section-title">Co-Founders</h2>

          <div className="about__founders-columns">
            <div className="about__founders-left about__founders-column">
              <h3>Margo Behler</h3>
              <Card className="about__name-card">
                <div className="about__image-wrapper">
                  <GatsbyImage
                    image={data.margoImage.childImageSharp.gatsbyImageData}
                    className="about__image" />
                </div>
                <p className="about__name">Mother/Gardener</p>
              </Card>
              <p className="paragraph">
                I am a grower at heart. No matter where I moved throughout life,
                I have always grown flowers or plants. I have what others would
                call a green thumb and I always felt at home. This land has a
                special meaning as it connects me to my family and roots. It is
                my way of relaxing as I move among the flowers and buzzing bees.
                My favorite moment of the day is deadheading as the sun goes
                down while my dogs run through the field, and then going to swim
                in lake Michigan with them and my daughter! I hope to continue
                to grow my farm and pass down a love for growing to more
                generations of my family.
              </p>
            </div>
            <div className="about__founders-right about__founders-column">
              <h3>Bay Nordstrom</h3>
              <Card className="about__name-card">
                <div className="about__image-wrapper">
                  <GatsbyImage
                    image={data.bayImage.childImageSharp.gatsbyImageData}
                    className="about__image" />
                </div>
                <p className="about__name">Daughter/Business</p>
              </Card>
              <p className="paragraph">
                I had always dreamed of slowing down and following in my
                family's footsteps. I worked with my grandpa for years on the
                cherry farm and watched my mom cultivate the most stunning
                gardens. My favorite memories include flowers whether it was the
                bouquets my dad gave me every year at my ballet recitals or
                planning the flowers for my wedding held on the farmhouse on the
                property. At Bay Meadow Farms, I help my mother in the gardens,
                and I also focus on all things business and branding! As a
                Virgo, I have always been analytical, organized, but also
                creative. I worked in fashion as a merchandiser and am a
                5th-grade literacy teacher. My dream is to watch my kids run
                through the flower field chasing Winnie, the best Dachshund
                ever!
              </p>
            </div>
          </div>
        </div>
      </div>
      <ContactSection />
    </Layout>
  );
}

export default about
